<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Retail : {{RetailCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="RetailCode" v-model="RetailCode" class="font-weight-bold"/>
                            <label id="errorRetailCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nama</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="RetailName" v-model="RetailName" class="font-weight-bold" />
                            <label id="errorRetailName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Tgl. Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="RetailDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorRetailDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Durasi (bulan)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="Duration" v-model="Duration" class="font-weight-bold" />
                            <label id="errorDuration" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                            <div class="col-sm-label">
                                <label class="form-label font-weight-bold">Diskon Cash (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="DiscCash" v-model="DiscCash" class="font-weight-bold" @change="onChangeDiscCash()"/>
                            <label id="errorDiscCash" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Area</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Area" class="pb-3" :options="AreaData" v-model="Area"/>
                            <label id="errorArea" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Jenis Harga</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="PriceType" class="pb-3" :options="PriceTypeData" v-model="PriceType" @input="onChangePriceType()" />
                            <label id="errorPriceType" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Salesman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Salesman" class="pb-3" :options="SalesmanData" v-model="Salesman" />
                            <label id="errorSalesman" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Cara Bayar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Cash" class="font-weight-bold" :options="CashData" :value.sync="Cash" @change="onChangeCash()"/>
                            <label id="errorCash" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Promo</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Promo" class="font-weight-bold" :options="PromoData" :value.sync="Promo" @change="onChangePromo()"/>
                            <label id="errorForecast" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Forecast</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Forecast" class="font-weight-bold" :options="ForecastData" :value.sync="Forecast" @change="onChangeForecast()" />
                            <label id="errorForecast" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                                <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" @input="onChangeStorage()"/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="TotalPaket" v-model="TotalPaket" class="font-weight-bold" />
                            <label id="errorTotalPaket" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Jumlah PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="TotalPO" v-model="TotalPO" class="font-weight-bold" />
                            <label id="errorTotalPO" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Limit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="Limit" v-model="Limit" class="font-weight-bold" />
                            <label id="errorLimit" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Provinsi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                            <label id="errorProvince" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kota</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                            <label id="errorCity" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Keterangan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <hr>

                    <CTabs>
                        <CTab title="Tipe Detail" id="tab-1" active>
                            <div class="py-2" v-if="this.Forecast == 'None'">
                                <button class="btn btn-primary"                     
                                    @click="checkAllTypeDetailClick(true)">
                                    <i class="fa fa-check"></i> Check All
                                </button>
                                &nbsp;
                                <button class="btn btn-danger"                     
                                    @click="checkAllTypeDetailClick(false)">
                                    <i class="fa fa-square"></i> Uncheck All
                                </button>
                            </div>
                            <div class="py-2">
                                <datasource ref="typeDetailDataSource" :data="this.TypeDetailGridData" :page-size="10" :schema-model-fields="this.TypeDetailSchemaModel"/>
                                
                                <div v-if="(this.Forecast == 'None' && this.Promo == 'None' || this.Forecast == 'None' && this.Promo == 'Total')">
                                    <kendo-grid ref="gridTypeDetail"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :columns="this.TypeDetailGridColumnsNone"
                                        :editable="true"
                                        :cellClose="onChangeTypeDetailGrid"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true">
                                    </kendo-grid>
                                </div>
                                <div v-else>
                                    <kendo-grid ref="gridTypeDetail"
                                        :data-source-ref="'typeDetailDataSource'"
                                        :columns="this.TypeDetailGridColumns"
                                        :editable="true"
                                        :cellClose="onChangeTypeDetailGrid"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true">
                                    </kendo-grid>
                                </div>
                            </div>
                        </CTab>
                        <CTab title="Distributor" id="tab-2" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('distributor')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                            
                            <datasource ref="distributorDataSource" :data="this.DistributorGridData" :page-size="10"/>

                            <kendo-grid ref="gridDistributor"
                                :data-source-ref="'distributorDataSource'"
                                :columns="this.DistributorGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <distributor-form ref="distributorForm" :saveDistributorGrid="saveDistributorGrid"/>
                        </CTab>
                        <CTab title="Sales" id="tab-3" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('sales')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        
                            <datasource ref="salesDataSource" :data="this.SalesGridData" :page-size="10" :schema-model-fields="this.SalesSchemaModel"/>

                            <kendo-grid ref="gridSales"
                                :data-source-ref="'salesDataSource'"
                                :columns="this.SalesGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <sales-form ref="salesForm" :saveSalesGrid="saveSalesGrid"/> 
                        </CTab>
                        <CTab title="Lain-lain" id="tab-4" active>
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('other')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="otherDataSource" :data="this.OtherGridData" :page-size="10" :schema-model-fields="this.OtherSchemaModel"/>

                            <kendo-grid ref="gridOther"
                                :data-source-ref="'otherDataSource'"
                                :columns="this.OtherGridColumns"
                                :editable="true"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <other-form ref="otherForm" :saveOtherGrid="saveOtherGrid"/> 
                        </CTab>
                        <CTab title="Forecast" id="tab-5" v-if="this.Forecast == 'Forecast 1'">
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('Forecast 1')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="forecastDataSource" :data="this.ForecastGridData" :page-size="10" :schema-model-fields="this.Forecast1SchemaModel"
                                :aggregate="[
                                    { field: 'product_code', aggregate: 'count' },
                                    { field: 'total_price_retail', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount_cash', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridForecast"
                                :key="gridReload"
                                :data-source-ref="'forecastDataSource'"
                                :columns="this.Forecast1GridColumns"
                                :editable="true"
                                :cellClose="recountForecast"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <forecast-form ref="forecastForm" :totalPO="this.TotalPO" :saveForecastGrid="saveForecastGrid" v-bind:price-type="this.PriceType" v-bind:storage="this.Storage" />
                        </CTab>
                        <CTab title="Forecast" id="tab-6" v-if="this.Forecast == 'Forecast 2'">
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('Forecast 2')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="forecastDataSource" :data="this.ForecastGridData" :page-size="10" :schema-model-fields="this.Forecast2SchemaModel"
                                :aggregate="[
                                    { field: 'product_code', aggregate: 'count' },
                                    { field: 'total_price_retail', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount_cash', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridForecast"
                                :key="gridReload"
                                :data-source-ref="'forecastDataSource'"
                                :columns="this.Forecast2GridColumns"
                                :editable="true"
                                :cellClose="recountForecast"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <forecast-form ref="forecastForm" :totalPO="this.TotalPO" :saveForecastGrid="saveForecastGrid" v-bind:price-type="this.PriceType" v-bind:storage="this.Storage" />
                        </CTab>
                        <CTab title="Paket" id="tab-7" v-if="this.Promo == 'Paket'">
                            <div class="py-2">
                                <button class="btn btn-primary"                     
                                    @click="addGridClick('Paket')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>

                            <datasource ref="forecastDataSource" :data="this.ForecastGridData" :page-size="10" :schema-model-fields="this.PaketSchemaModel"
                                :aggregate="[
                                    { field: 'product_code', aggregate: 'count' },
                                    { field: 'total_price_retail', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount', aggregate: 'sum' },
                                    { field: 'total_price_retail_discount_cash', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridForecast"
                                :key="gridReload"
                                :data-source-ref="'forecastDataSource'"
                                :columns="this.PaketGridColumns"
                                :editable="true"
                                :cellClose="recountForecast"
                                :filterable="true"
                                :sortable="true"
                                :pageable="true"
                                :resizable="true">
                            </kendo-grid>

                            <forecast-form ref="paketForm" :totalPO="this.TotalPO" :saveForecastGrid="saveForecastGrid" v-bind:price-type="this.PriceType" v-bind:storage="this.Storage" />
                        </CTab>
                    </CTabs>
                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/retail')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import projectServices from '../../Project/Script/ProjectServices';
import retailServices from '../Script/RetailServices';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import FileViewer from '../../../../shared/FileViewer.vue';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { project } from '../../../../infrastructure/constant/variable';

//Form
import DistributorForm from '../../Project/Component/DistributorForm.vue';
import ForecastForm from './ForecastForm.vue';
import SalesForm from '../../Project/Component/SalesForm.vue';
import OtherForm from '../../Project/Component/OtherForm.vue';

export default {
    name: 'RetailFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'distributor-form': DistributorForm,
        'sales-form': SalesForm,
        'other-form': OtherForm,
        'forecast-form': ForecastForm,
        'file-viewer': FileViewer,
    },
    async mounted () {
        this.$loading(true);
        if (this.FormType == 'Add') {
            this.RetailCode = 'Auto';
            this.RetailName = null;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.RetailDate = dateNow;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = null;

            this.AreaData = await projectServices.getAreaQuery();
            this.Area = null;
            this.Note = null;
            this.Limit = 0;
            this.Duration = 0;
            this.DiscCash = 0;
            this.PriceTypeData = await projectServices.getPriceTypeQuery();
            this.PriceType = null;
            this.SalesmanData = await projectServices.getKacabQuery();
            this.Salesman = null;
            this.Cash = this.CashData[0].value;
            this.PromoData = await globalfunc.globalDropdown('ddl_promo_type');
            this.Promo = 'None'
            this.TotalPaket = 0;
            this.ForecastData = await globalfunc.globalDropdown('ddl_forecast_type');
            this.Forecast = 'None';
            this.TotalPO = 0;
            this.StorageData = await projectServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == project.gudang_cikupa);
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            //File
            this.File = '';
            this.FileStatus = 'New';

            //grid
            this.TypeDetailGridData = await retailServices.getTypeDetailQuery();
            this.DistributorGridData = [];
            this.SalesGridData = [];
            this.OtherGridData = [];
            this.ForecastGridData = [];

            this.SaveType = 'Add';
            document.getElementById('RetailCode').readOnly = false;
            document.getElementById('TotalPaket').readOnly = true;
            document.getElementById('TotalPO').readOnly = true;
            document.getElementById('Forecast').removeAttribute("disabled");
            document.getElementById('Promo').removeAttribute("disabled");;
            $('#tab-1').trigger('click');
        }
        else{
            var data = await projectServices.getProjectDetailQuery(this.Id); 
            
            this.RetailCode = data.project_code;
            this.RetailName = data.project_name;
            this.RetailDate = data.project_date;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == data.project_detail.provinsi);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == data.city);

            this.AreaData = await projectServices.getAreaQuery();
            this.Area = this.AreaData.find(c => c.value == data.areacode);
            this.Note = data.notes;
            this.Limit = data.project_detail.limit;
            this.Duration = data.project_duration;
            this.DiscCash =  data.project_detail.disc_cash;
            this.PriceTypeData = await projectServices.getPriceTypeQuery();
            this.PriceType = this.PriceTypeData.find(c => c.value == data.price_category_id);
            this.SalesmanData = await projectServices.getKacabQuery();
            this.Salesman = this.SalesmanData.find(c => c.value == data.sales_id);
            this.Cash = data.project_detail.cash;
            this.PromoData = await globalfunc.globalDropdown('ddl_promo_type');
            this.Promo = 'None';
            this.TotalPaket = data.project_detail.total;
            this.ForecastData = await globalfunc.globalDropdown('ddl_forecast_type');
            this.Forecast = 'None';
            this.TotalPO = data.project_detail.total_po;
            this.StorageData = await projectServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);
            this.StatusData = await globalfunc.globalDropdown('ddl_status_project');
            this.Status = data.status;

            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';

            //grid
            this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(data.project_discount, 'Project-ProjectTypeDetail');
            this.DistributorGridData = this.$globalfunc.objectToArrayConverter(data.project_distributor, 'Project-ProjectDistributorGrid');
            this.SalesGridData = this.$globalfunc.objectToArrayConverter(data.project_sales, 'Project-ProjectSalesGrid');
            this.OtherGridData = this.$globalfunc.objectToArrayConverter(data.project_etc, 'Project-ProjectOther');

            if(data.forecast_type == 'None'){
                this.Promo = 'None';
                this.Forecast = 'None';
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = false;
                document.getElementById('TotalPaket').readOnly = true;
            }
            else if(data.forecast_type == 'Forecast 1'){
                this.Forecast = 'Forecast 1';
                document.getElementById('TotalPO').readOnly = false;
                document.getElementById('Limit').readOnly = true;
                document.getElementById('TotalPaket').readOnly = true;
                document.getElementById('Promo').setAttribute("disabled", true);
                this.ForecastGridData = this.$globalfunc.objectToArrayConverter(data.project_item_forcast, 'Retail-ForecastReverse');
            }
            else if(data.forecast_type == 'Forecast 2'){
                this.Forecast = 'Forecast 2';
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = true;
                document.getElementById('TotalPaket').readOnly = true;
                document.getElementById('Promo').setAttribute("disabled", true);
                this.ForecastGridData = this.$globalfunc.objectToArrayConverter(data.project_item_forcast, 'Retail-ForecastReverse');
            }
            else if(data.forecast_type == 'Paket'){
                this.Promo = 'Paket';
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = true;
                document.getElementById('TotalPaket').readOnly = true;
                document.getElementById('Forecast').setAttribute("disabled", true);
                this.ForecastGridData = this.$globalfunc.objectToArrayConverter(data.project_item_forcast, 'Retail-ForecastReverse');
            }
            else if(data.forecast_type == 'Total'){
                this.Promo = 'Total';
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Limit').readOnly = true;
                document.getElementById('TotalPaket').readOnly = false;
                document.getElementById('Forecast').setAttribute("disabled", true);
            }
            
            this.SaveType = 'Edit';
            document.getElementById('RetailCode').readOnly = true;
            $('#tab-1').trigger('click');

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
        this.$loading(false);
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,
            gridReload: 0,

            //Detail
            RetailCode: '',
            RetailName: '',
            RetailDate: '',
            Duration: 0,
            DiscCash: 0,
            Province : '',
            ProvinceData : [],
            City : '',
            CityData : [],
            AreaData: [],
            Area: '',
            PriceTypeData: [],
            PriceType: '',
            SalesmanData: [],
            Salesman: '',
            CashData: [ {value: 'Cash', label: 'Cash'},
                        {value: 'Credit', label: 'Credit'}],
            Cash: '',
            PromoData: [],
            Promo: '',
            ForecastData: [],
            Forecast: '',
            TotalPaket: 0,
            TotalPO: 0,
            Storage : '',
            StorageData : [],
            Note: '',
            Limit: 0,
            StatusData: [],
            Status: '',

            File: '',
            FileStatus: '',

            //Grid
            TypeDetailGridData: [],
            TypeDetailGridColumns:  [
                { field: "type_detail_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Name", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } }
            ],
            TypeDetailGridColumnsNone:  [
                { field: "type_detail_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Name", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "discount1", title: "Diskon 1 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount2", title: "Diskon 2 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount3", title: "Diskon 3 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount4", title: "Diskon 4 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "discount5", title: "Diskon 5 (%)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: 'is_shown', title: "Tampilkan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<input type="checkbox" #= is_shown ? \'checked="checked"\' : "" # class="k-checkbox k-checkbox-md k-rounded-md" />' },
            ],
            TypeDetailSchemaModel: {
                type_detail_id: { type: "string", editable: false },
                type_detail_name: { type: "string", editable: false },
                discount1: { type: "number",editable: true, validation: { min:0 } },
                discount2: { type: "number",editable: true, validation: { min:0 } },
                discount3: { type: "number",editable: true, validation: { min:0 } },
                discount4: { type: "number",editable: true, validation: { min:0 } },
                discount5: { type: "number",editable: true, validation: { min:0 } },
                is_shown: {type: "boolean",editable: true, validation: { min:0 } },
                disc_cash: { type: "number",editable: false},
            },

            DistributorGridData: [],
            DistributorGridColumns:  [
                { field: "distributor_code", title: "Kode", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "distributor_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],

            SalesGridData: [],
            SalesGridColumns:  [
                { field: "sales_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Posisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            SalesSchemaModel: {
                sales_name: { type: "string", editable: false },
                position: { type: "string",editable: false },
            },

            OtherGridData: [],
            OtherGridColumns:  [
                { field: "contact_name", title: "Nama", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "position", title: "Posisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } }
            ],
            OtherSchemaModel: {
                contact_name: { type: "string", editable: false },
                position: { type: "string",editable: false },
            },

            ForecastGridData: [],
            Forecast1GridColumns: [
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } },
                { field: "po", title: "PO", width: 75, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-center" } },
                { field: "product_code", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    footerTemplate: "Total Baris<br>#= count #", footerAttributes: { "class": "k-text-center" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_uom", title: "Satuan Dasar", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "uom_conv", title: "Satuan Konversi", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "conv_amount", title: "Nilai Konversi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "quantity", title: "Quantity", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "price_retail", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail", title: "Total Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount", title: "Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, 
                    footerTemplate: "Total Harga Diskon", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount", title: "Total Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount_cash", title: "Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga Diskon Cash", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount_cash", title: "Total Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
            ],
            Forecast1SchemaModel: {
                po: { type: "number", editable: false },
                item_id: { type: "number", editable: false },
                product_code: { type: "string",editable: false },
                item_name: { type: "string",editable: false },
                type_detail_id: { type: "string",editable: false },
                base_uom: { type: "string", editable:false },
                uom_conv: { type: "string", editable:false },
                conv_amount: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
                //price
                base_price_retail: { type: "number", editable: false },
                price_retail: { type: "number", editable: false },
                total_price_retail: { type: "number", editable: false },
                //price discount
                base_price_retail_discount: { type: "number", editable: false },
                price_retail_discount: { type: "number", editable: false },
                total_price_retail_discount: { type: "number", editable: false },
                //price discount cash
                base_price_retail_discount_cash: { type: "number", editable: false },
                price_retail_discount_cash: { type: "number", editable: false },
                total_price_retail_discount_cash: { type: "number", editable: false },
            },
            Forecast2GridColumns: [
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } },
                { field: "product_code", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    footerTemplate: "Total Baris<br>#= count #", footerAttributes: { "class": "k-text-center" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_uom", title: "Satuan Dasar", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "uom_conv", title: "Satuan Konversi", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "conv_amount", title: "Nilai Konversi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "quantity", title: "Quantity", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "price_retail", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail", title: "Total Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount", title: "Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, 
                    footerTemplate: "Total Harga Diskon", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount", title: "Total Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount_cash", title: "Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga Diskon Cash", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount_cash", title: "Total Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
            ],
            Forecast2SchemaModel: {
                item_id: { type: "number", editable: false },
                product_code: { type: "string",editable: false },
                item_name: { type: "string",editable: false },
                type_detail_id: { type: "string",editable: false },
                base_uom: { type: "string", editable:false },
                uom_conv: { type: "string", editable:false },
                conv_amount: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
                //price
                base_price_project: { type: "number", editable: false },
                price_project: { type: "number", editable: false },
                total_price_project: { type: "number", editable: false },
                //price discount
                base_price_project_discount: { type: "number", editable: false },
                price_project_discount: { type: "number", editable: false },
                total_price_project_discount: { type: "number", editable: false },
                //price discount cash
                base_price_project_discount_cash: { type: "number", editable: false },
                price_project_discount_cash: { type: "number", editable: false },
                total_price_project_discount_cash: { type: "number", editable: false },
            },
            PaketGridColumns: [
                { command: ['destroy'], title:'&nbsp;', width: 100, attributes: { "class": "k-text-center" } },
                { field: "product_code", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    footerTemplate: "Total Baris<br>#= count #", footerAttributes: { "class": "k-text-center" } },
                { field: "item_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "base_uom", title: "Satuan Dasar", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "uom_conv", title: "Konversi Satuan", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "quantity", title: "Quantity", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "price_retail", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail", title: "Total Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount", title: "Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }, 
                    footerTemplate: "Total Harga Diskon", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount", title: "Total Harga Diskon", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
                { field: "price_retail_discount_cash", title: "Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "Total Harga Diskon Cash", format:"{0:N0}", footerAttributes: { "class": "k-text-center" } },
                { field: "total_price_retail_discount_cash", title: "Total Harga Diskon Cash", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    footerTemplate: "#= kendo.toString(sum, 'N0') #", format:"{0:N0}", footerAttributes: { "class": "k-text-right" } },
            ],
            PaketSchemaModel: {
                item_id: { type: "number", editable: false },
                product_code: { type: "string",editable: false },
                item_name: { type: "string",editable: false },
                type_detail_id: { type: "string",editable: false },
                base_uom: { type: "string", editable:false },
                uom_conv: { type: "string", editable:false },
                conv_amount: { type: "number", editable: false },
                quantity: { type: "number", editable: true, validation: { min:1 } },
                //price
                base_price_project: { type: "number", editable: false },
                price_project: { type: "number", editable: false },
                total_price_project: { type: "number", editable: false },
                //price discount
                base_price_project_discount: { type: "number", editable: false },
                price_project_discount: { type: "number", editable: false },
                total_price_project_discount: { type: "number", editable: false },
                //price discount cash
                base_price_project_discount_cash: { type: "number", editable: false },
                price_project_discount_cash: { type: "number", editable: false },
                total_price_project_discount_cash: { type: "number", editable: false },
            },
        }
    },
    methods: {
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        checkAllTypeDetailClick(e){
            var data = this.$globalfunc.objectToArrayConverter(this.$refs.gridTypeDetail.kendoWidget().dataSource._data, 'Project-ProjectTypeDetail');
            data.map(function(x) { 
                x.is_shown = e; 
                return x
            });
            this.TypeDetailGridData = data;
            
            this.gridTypeDetailReload++;
        },
        onChange(){
            this.City = '';
            if(this.Province == null){
                this.CityData = [];
            }else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        onChangeForecast(){
            if(this.Forecast == 'Forecast 1'){
                this.TotalPO = 1;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = false;
                document.getElementById('Promo').setAttribute("disabled", true);
                document.getElementById('Limit').readOnly = true;
            }else if(this.Forecast == 'Forecast 2'){
                this.TotalPO = 0;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Promo').setAttribute("disabled", true);
                document.getElementById('Limit').readOnly = true;
            }else{
                this.TotalPO = 0;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPO').readOnly = true;
                document.getElementById('Promo').removeAttribute("disabled");
                document.getElementById('Limit').readOnly = false;
            }
            //refresh isi grid forecast
            this.resetForecast();
            this.resetTypeDetail();
        },
        onChangePromo(){
            if(this.Promo == 'Paket'){
                this.TotalPO = 0;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPaket').readOnly = true;
                document.getElementById('Forecast').setAttribute("disabled", true);
                document.getElementById('Limit').readOnly = true;
            }else if(this.Promo == 'Total'){
                this.TotalPO = 0;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPaket').readOnly = false;
                document.getElementById('Forecast').setAttribute("disabled", true);
                document.getElementById('Limit').readOnly = true;
            }else{
                this.TotalPO = 0;
                this.TotalPaket = 0;
                this.Limit = 0;
                document.getElementById('TotalPaket').readOnly = true;
                document.getElementById('Forecast').removeAttribute("disabled");
                document.getElementById('Limit').readOnly = false;
            }
            //refresh isi grid forecast
            this.resetForecast();
            this.resetTypeDetail();
        },
        onChangeStorage(){
            //refresh isi grid forecast
            this.resetForecast();
        },
        onChangePriceType(){
            //refresh isi grid forecast
            this.resetForecast();
        },
        onChangeCash(){
            if (this.Cash == 'Credit') {
                this.DiscCash = 0;
                document.getElementById('DiscCash').readOnly = true;
            }
            else {
                document.getElementById('DiscCash').readOnly = false;
            }
        },
        addGridClick(type){
            if(type == 'distributor'){
                this.$refs.distributorForm.addClick();
            }
            if(type == 'sales'){
                this.$refs.salesForm.addClick();
            }
            if(type == 'other'){
                this.$refs.otherForm.addClick();
            }
            if(type == 'Forecast 1'){
                if(this.PriceType == '' || this.PriceType == null){
                    this.$swal("Error", "Jenis Harga harus dipilih terlebih dahulu", "error");
                    return;
                }
                if(this.Storage == '' || this.Storage == null){
                    this.$swal("Error", "Gudang harus dipilih terlebih dahulu", "error");
                    return;
                }
                if(String(this.TotalPO) == '' || String(this.TotalPO) == null || this.TotalPO <= 0){
                    this.$swal("Error", "Minimal jumlah PO adalah 1", "error");
                    return;
                }
                else {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                }
                this.$refs.forecastForm.addClick('Forecast 1');
            }
            if(type == 'Forecast 2'){
                if(this.PriceType == '' || this.PriceType == null){
                    this.$swal("Error", "Jenis Harga harus dipilih terlebih dahulu", "error");
                    return;
                }
                if(this.Storage == '' || this.Storage == null){
                    this.$swal("Error", "Gudang harus dipilih terlebih dahulu", "error");
                    return;
                }
                else {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                }
                this.$refs.forecastForm.addClick('Forecast 2');
            }
            if(type == 'Paket'){
                if(this.PriceType == '' || this.PriceType == null){
                    this.$swal("Error", "Jenis Harga harus dipilih terlebih dahulu", "error");
                    return;
                }
                if(this.Storage == '' || this.Storage == null){
                    this.$swal("Error", "Gudang harus dipilih terlebih dahulu", "error");
                    return;
                }
                this.$refs.paketForm.addClick('Paket');
            }
        },
        forecastValidation(){
            if(this.PriceType == '' || this.PriceType == null){
                this.$swal("Error", "Jenis Harga harus dipilih terlebih dahulu", "error");
                return;
            }
            if(this.Storage == '' || this.Storage == null){
                this.$swal("Error", "Gudang harus dipilih terlebih dahulu", "error");
                return;
            }
        },
        saveDistributorGrid(data){
            var gridData = this.$refs.gridDistributor.kendoWidget().dataSource._data;
            var distributorData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectDistributorGrid');
            distributorData = distributorData.concat(data);
            this.DistributorGridData  = Object.values(distributorData.reduce((a, c) => (a[`${c.distributor_id}${c.distributor_code}${c.distributor_name}`] = c, a), {})); 
        },
        saveSalesGrid(data){
            var gridData = this.$refs.gridSales.kendoWidget().dataSource._data;
            var salesData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectSalesGrid');
            salesData = salesData.concat(data);
            this.SalesGridData  = Object.values(salesData.reduce((a, c) => (a[`${c.sales_id}${c.sales_name}${c.position}`] = c, a), {})); 
        },
        saveOtherGrid(data){
            var gridData = this.$refs.gridOther.kendoWidget().dataSource._data;
            var otherData = this.$globalfunc.objectToArrayConverter(gridData, 'Project-ProjectOther');
            otherData = otherData.concat(data);
            this.OtherGridData  = Object.values(otherData.reduce((a, c) => (a[`${c.contact_id}${c.contact_name}${c.position}`] = c, a), {})); 
        },
        saveForecastGrid(data){
            var gridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
            var currentData = this.$globalfunc.objectToArrayConverter(gridData, 'Retail-ForecastInitiate');
            var forecastData = data[0];

            var duplicate = projectServices.duplicateForecastChecker(currentData, forecastData);
            if(duplicate){
                if(this.Forecast == 'Forecast 1'){
                    this.$swal("Error", "Item sudah ada, tidak dapat menginput kode item yang sama pada 1 PO", "error");
                }else if(this.Forecast == 'Forecast 2' || this.Promo == 'Paket'){
                    this.$swal("Error", "Item sudah ada, tidak dapat menginput kode item yang sama", "error");
                }
            }else{
                var typeDetailData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                currentData.push(retailServices.calculateForecastData(forecastData, typeDetailData, this.DiscCash));
            }
            this.ForecastGridData = currentData;
        },
        resetForecast(){
            if(this.ForecastGridData.length > 0){
                this.ForecastGridData = [];
                this.gridReload++;
            }
        },
        async resetTypeDetail(){
            this.TypeDetailGridData = await projectServices.getTypeDetailQuery();
        },
        onChangeTypeDetailGrid(e){
            var grid = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var dataItem = e.sender.dataItem($(e.container).parent());
            
            dataItem.discount1 = this.$globalfunc.percentValidate(dataItem.discount1);
            dataItem.discount2 = this.$globalfunc.percentValidate(dataItem.discount2);
            dataItem.discount3 = this.$globalfunc.percentValidate(dataItem.discount3);
            dataItem.discount4 = this.$globalfunc.percentValidate(dataItem.discount4);
            dataItem.discount5 = this.$globalfunc.percentValidate(dataItem.discount5);

            this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(grid, 'Project-ProjectTypeDetail');

            if(this.Forecast !== 'None'){
                this.recountForecast();
            }
            if(this.Promo !== 'None'){
                if(this.Promo !== 'Total'){
                    this.recountForecast();
                }
            }
        },
        recountForecast(){
            var gridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
            var typeDetailData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;

            let currentData  = [];
            for (let i = 0; i < gridData.length; i++) {
                currentData.push(retailServices.calculateForecastData(gridData[i], typeDetailData, this.DiscCash));
            }
            this.ForecastGridData = currentData;
        },
        onChangeDiscCash(){
            this.DiscCash = this.$globalfunc.percentValidate(this.DiscCash);

            if(this.Forecast !== 'None'){
                this.recountForecast();
            }
            if(this.Promo !== 'None'){
                if(this.Promo !== 'Total'){
                    this.recountForecast();
                }
            }
        },
        inputProjectValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.RetailCode == '' || this.RetailCode == null){
                this.errorShow('errorRetailCode');
            }
            if(this.RetailName == '' || this.RetailName == null){
                this.errorShow('errorRetailName');
            }
            if(this.RetailDate == '' || this.RetailDate == null){
                this.errorShow('errorRetailDate');
            }
            if(this.Salesman == '' || this.Salesman == 0 || this.Salesman == null){
                this.errorShow('errorSalesman');
            }
            if(this.Province == '' || this.Province == null){
                this.errorShow('errorProvince');
            }
            if(this.City == '' || this.City == null){
                if(this.Forecast == 'None' && this.Promo == 'None'){
                }else{
                    this.errorShow('errorCity');
                }
            }
            if(this.Duration == 0 || this.Duration == null || this.Duration == ''){
                this.errorShow('errorDuration');
            }
            if(String(this.DiscCash) == null || String(this.DiscCash) == ''){
                this.errorShow('errorDiscCash');
            }else if(this.DiscCash > 100){
                this.$swal("Error", "Diskon  Cash tidak boleh lebih dari 100", "error" )
                this.Error++;
            }
            if(this.Salesman == '' || this.Salesman == null){
                this.errorShow('errorSalesman');
            }
            if(this.Area == '' || this.Area == null){
                this.errorShow('errorArea');
            }
            if(this.PriceType == '' || this.PriceType == null){
                this.errorShow('errorPriceType');
            }
            if(this.Storage == '' || this.Storage == null){
                this.errorShow('errorStorage');
            }
            if(this.Promo == 'Total'){
                if(this.TotalPaket == 0){
                    this.errorShow('errorTotalPaket');
                }
            }
            if(this.Promo == 'Paket'){
                if(this.ForecastGridData.length == 0){
                    this.$swal("Error", "Item pada Paket tidak boleh kosong", "error");
                    this.Error++;
                }
            }
            if(this.DistributorGridData.length == 0){
                this.$swal("Error", "Distributor tidak boleh kosong", "error");
                this.Error++;
            }
            if(this.Forecast == 'None' && this.Promo == 'None'){
                if(this.Limit == 0 || String(this.Limit) == null || String(this.Limit) == ''){
                    this.errorShow('errorLimit');
                }
            }
            if(this.Forecast != 'None'){
                if(this.ForecastGridData.length == 0){
                    this.$swal("Error", "Item pada Forecast tidak boleh kosong", "error");
                    this.Error++;
                }
            }

            if(this.Duration.toString() == '' || this.Duration == null){
                this.errorShow('errorDuration');
            }
            else {
                if(this.Duration < 0) {
                    this.errorShow('errorDuration', response.fieldRequiredNotMinus);
                }
            }
            
            if(this.Limit.toString() == '' || this.Limit == null){
                this.errorShow('errorLimit');
            }
            else {
                if(this.Limit < 0) {
                    this.errorShow('errorLimit', response.fieldRequiredNotMinus);
                }
            }
        },
        saveClick(){
            this.inputProjectValidation();

            //file
            var file = null
            if(this.File == '' || this.File == null){
                file = null
            } else {
                file = document.getElementById('File').files[0];
            };

            //distributor
            var distributorGridData = this.$refs.gridDistributor.kendoWidget().dataSource._data;
            var distributorData = this.$globalfunc.objectToArrayConverter(distributorGridData, 'Project-ProjectDistributorGrid');

            //typeDetail
            var typeDetailGridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var typeDetailData = [];
            for (let i = 0; i < typeDetailGridData.length; i++) {
                var str = { type_detail_id: typeDetailGridData[i].type_detail_id, 
                            type_detail_name: typeDetailGridData[i].type_detail_name,
                            discount1: typeDetailGridData[i].discount1,
                            discount2: typeDetailGridData[i].discount2,
                            discount3: typeDetailGridData[i].discount3,
                            discount4: typeDetailGridData[i].discount4,
                            discount5: typeDetailGridData[i].discount5,
                            is_shown: typeDetailGridData[i].is_shown,
                            discount_cash: parseFloat(this.DiscCash)}
                typeDetailData.push(str);
            };
            
            // //sales
            var salesGridData = this.$refs.gridSales.kendoWidget().dataSource._data;
            var salesData = this.$globalfunc.objectToArrayConverter(salesGridData, 'Project-ProjectSalesGrid');
          
            //other
            var otherGridData = this.$refs.gridOther.kendoWidget().dataSource._data;
            let otherData = this.$globalfunc.objectToArrayConverter(otherGridData, 'Project-ProjectOther');

            //forecast
            let forecastData = [];
            if(this.Forecast != "None"){
                var forecastGridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
                forecastData = this.$globalfunc.objectToArrayConverter(forecastGridData, 'Retail-ForecastSave');
                this.Limit = retailServices.countTotal(forecastGridData);
            }else{
                if(this.Promo == "Paket"){
                    var forecastGridData = this.$refs.gridForecast.kendoWidget().dataSource._data;
                    forecastData = this.$globalfunc.objectToArrayConverter(forecastGridData, 'Retail-ForecastSave');
                    this.Limit = retailServices.countTotal(forecastGridData);
                }
            }
            
            var forecastType = 'None';
            if(this.Forecast != "None"){
                forecastType = this.Forecast;
            }else {
                if (this.Promo != "None"){
                    forecastType = this.Promo;
                }
            }

            //Proses
            if(this.Error == 0){
                const detailRetailData = {
                    limit : parseFloat(this.Limit),
                    provinsi : this.Province.value,
                    total : parseFloat(this.TotalPaket),
                    disc_cash : parseFloat(this.DiscCash),
                    total_po : parseFloat(this.TotalPO),
                    cash : this.Cash,
                }
                
                const retailData = {
                    project_code: this.RetailCode,
                    project_type: 2, //project = 1, template = 0, retail = 2
                    project_date: this.RetailDate,
                    project_duration: parseInt(this.Duration),
                    project_name: this.RetailName,
                    status: this.Status,
                    pre_project_id: null,
                    forecast_type: forecastType,
                    storage_id: this.Storage.value,
                    price_category_id: this.PriceType.value,
                    areacode: this.Area.value,
                    sales_id: this.Salesman.value,
                    city: this.City == null ? "" : this.City.value,
                    attachment: file,
                    notes: this.Note,
                    project_detail: detailRetailData,
                    project_affiliate: null,
                    project_distributor: distributorData,
                    project_consultant: null,
                    project_sales: salesData,
                    project_etc: otherData,
                    project_discount: typeDetailData,
                    project_item_forcast: forecastData
                };

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : retailData
                    }
                    
                    projectServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/retail' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : retailData
                    }
                    
                    projectServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/retail'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>